








































































































































































































































































































































































































    import {
        Vue, Component, Watch, Provide
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import api from '~/api'
    import utils from '~/utils'
    import $config from '~/config'
    import CommonProductCard from '~/components/common-product-card.vue'
    import ImgLazyLoad from '~/components/img-lazy-load.vue'
    import common from '~/utils/common'
    import CommonNoResultNoImage from '~/components/common-no-result-noImage.vue'
    import ProductCover from '~/components/product-cover.vue'
    import FavoriteIcon from '~/components/FavoriteIcon.vue'
    import commonFun from '~/utils/commonFun'
    import Cookies from 'js-cookie'

@Component(<any> {
    components: {
        CommonProductCard,
        ImgLazyLoad,
        CommonNoResultNoImage,
        ProductCover,
        FavoriteIcon,
    },
    head() {
        const head = {
            title: 'EGG Mall - Home Page - We Make Car Repair Easy',
        }
        if (process.env.CUSTOM_ENV === 'production') {
            let domain = $config.eggmallWeb[process.env.CUSTOM_ENV]
            const searchJson = `{
                "@context":"https://schema.org",
                "@type":"WebSite",
                "url":"https://www.eggmalltech.com",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate":  "/${this.$store.state.langCode}/search/multiCondition.html?searchResultInfo=%7B%22keywords%22%3A%22{search_term_string}%22%7D&keywords={search_term_string}&searchProduct=1&isSearchProductEntry=true"
                    },
                    "query-input": "required name=search_term_string"
                },
                "name":"EGGMall"
            }`
            Object.assign(head, {
                htmlAttrs: {
                    lang: this.$store.state.langCode,
                },
                meta: [{
                    hid: 'keywords', name: 'keywords', content: this.$t('home_keywords'),
                }, {
                    hid: 'og:title', name: 'title', property: 'og:title', content: 'EGG Mall - Home Page - We Make Car Repair Easy',
                }, {
                    hid: 'og:description', name: 'description', property: 'og:description', content: this.$t('home_description'),
                }, {
                    hid: 'og:type', property: 'og:type', content: 'website',
                }, {
                    hid: 'og:url', property: 'og:url', content: domain + this.$route.path,
                }],
                script: [
                    {
                        innerHTML: searchJson,
                        type: 'application/ld+json',
                        charset: 'utf-8',
                    },
                ],
            })
        }
        return head
    },
    async asyncData(context) {
        let header: any = {
            langCode: context.store.state.langCode,
            countryId: context.store.state.countryId,
            countryCode: context.store.state.countryCode,
        }

        // 登录状态，top products和you may like商品获取的接口需要传入token和priceId
        if (context.req && context.req.headers.cookie) {
            let cookieObj = context.req.headers.cookie.split(';').reduce((obj, item) => ({ ...obj, [item.split('=')[0].trim()]: item.split('=')[1].trim() }), {})
            const tmpToken = cookieObj.token || cookieObj.urlToken
            if (tmpToken) {
                const decodeToken = decodeURIComponent(tmpToken)
                if (/[+=]+/.test(decodeToken)) {
                    header.token = tmpToken
                } else {
                    header.token = decodeURIComponent(tmpToken)
                }
                header.priceId = cookieObj.priceId
                delete header.countryCode
                delete header.countryCode
            }
        }
        let topProductsPageParam = {
            limit: 5,
            page: 0,
            recommendType: 'PROMOTION',
        }

        let youMayLikePageParam = {
            eggMallPage: 1,
            showedSkuOrgIdList: [], // 搜索已经显示skuOrgId
            ruleOffset: {}, // Map key为rule value为offset
        }
        api.context = context
        let [categorys, banners, brands, fastmovings, topProducts, youMayLikeProducts, newPromotionRes, oePartsListRes] = await <any> Promise.all([
            api.client_goodsCategory_all({langCode: header.langCode}, header, context),
            api.cms_banners_pc_find({}, header, context),
            api.product_brands_get({}, header, context),
            api.fast_moving_list({}, header, context),
            api.get_recommend_goods_page(topProductsPageParam, header, context),
            api.get_you_may_like(youMayLikePageParam, header, context),
            api.get_new_promotion({}, header, context),
            api.get_oe_parts({}, header, context),
        ]).catch(err => {
        })
        let categoryList: any[] = (categorys && categorys.data) || []
        let carouselItems: any[] = []
        let popularBrandsGroup: any[] = []
        let fastMoveingsGroup: any[] = []
        let topProductList: any[] = []
        let youMayLikeProductList: any = {
            youMayLikeProducts: [],
            recommendGoodsList: [],
        }
        let newPromotion:any = {}

        let youMayLikeNoMoreData : Boolean = false
        newPromotion = (newPromotionRes && newPromotionRes.data) || []

        let oePartsList: any[] = []
        oePartsList = oePartsListRes.data

        topProductList = (topProducts && topProducts.data && topProducts.data.items) || []

        if (youMayLikeProducts && +youMayLikeProducts.code === 200 && youMayLikeProducts.data) {
            youMayLikeProductList = youMayLikeProducts.data
            youMayLikePageParam.ruleOffset = youMayLikeProductList.ruleOffset
            if (youMayLikeProductList.recommendGoodsList && youMayLikeProductList.recommendGoodsList.length < 10) {
                youMayLikeNoMoreData = true
            } else {
                youMayLikeNoMoreData = false
            }
            youMayLikeProductList.recommendGoodsList.map(item => {
                youMayLikePageParam.showedSkuOrgIdList.push(item.skuOrgId)
            })
        }

        categoryList.forEach(el => {
            el.icon = (el.icon && el.icon.match(/\/([^\/]+)\.(png|jpg)/)) ? el.icon.match(/\/([^\/]+)\.(png|jpg)/)[1] : ''
            let children = el.children
            if (children) {
                children.forEach(item => {
                    if (item.imgUrl !== null) {
                        item.genImgUrl = context.app.$dealImgUrl(item.imgUrl, 240)
                    }
                })
            }
        })

        carouselItems = ((banners && banners.data) || []).map(item => {
            item.genImgUrl = context.app.$dealImgUrl(item.imgUrl)
            return item
        })

        popularBrandsGroup = context.app.$commonSliceGroupFunc(brands && brands.data, 16)

        fastmovings = ((fastmovings && fastmovings.data) || []).map(item => {
            if (item.icon && item.icon.charAt(0) === '/') {
                item.genIconUrl = context.app.$dealImgUrl(item.icon, '', 260)
            } else {
                item.genIconUrl = context.app.$dealImgUrl(item.icon, '', 260)
            }
            return item
        })
        fastMoveingsGroup = context.app.$commonSliceGroupFunc(fastmovings, 16)
        return {
            categoryList,
            carouselItems,
            popularBrandsGroup,
            fastMoveingsGroup,
            topProductsPageParam,
            youMayLikePageParam,
            topProductList,
            youMayLikeProductList,
            newPromotion,
            oePartsList,
            youMayLikeNoMoreData,
        }
    },
})
    export default class HomePage extends Vue {
    @State('token') token

    @State('countryId') countryId

    @State('priceItem') priceItem

    @State('is3PScene') is3PScene

    @State('isAgreePolicy') isAgreePolicy

    @State('countryCurrency') currency

    @Provide() aDEntry = 'You may like in homepage'

    categoryList: any[] = []

    carouselItems: any[] = []

    popularBrandsGroup: any[] =[]

    shopListData: any[] = []

    fastMoveingsGroup: any[] = []

    topProductList: any[] = []

    youMayLikeProductList: any = {
        youMayLikeProductList: [],
    }

    topProductsPageParam: any = {}

    youMayLikePageParam: any = {}

    youMayLikeNoMoreData: any

    searchFastMoving: string = ''

    priceIdList: any[] = []

    selectedPriceItem: any = {}

    pointInfo: any = null

    @State('langCode') langCode

    mounted() {
        // 判断参数，打开网页
        if (this.$route.query.needJumpToActivityPage) {
            return utils.openBannerPromotionPage(this, this.$route.query.linkUrl)
        }

        if (sessionStorage.getItem('showChangeCountryTips')) {
            this.$message.success(this.$t('label_change_country_tips'))
            sessionStorage.removeItem('showChangeCountryTips')
        }
        this.$nextTick(() => {
            this.getShopList()
        })

        window.addEventListener('scroll', this.youMayLikeScrollHandler, false)
        if (this.topProductList.length) {
            utils.ga4('event', 'view_item_list', {
                item_list_id: 1,
                item_list_name: 'topProduct',
                items: this.topProductList.map(sku => ({
                    item_id: sku.skuOrgId,
                    item_name: sku.title,
                    currency: this.$store.state.currentCountry.currency,
                    item_brand: sku.brandName,
                    item_category: sku.thirdCategoryNameBuried,
                    price: sku.standardPrice,
                    item_category2: sku.shopId,
                })),
            })
        }
        if (this.youMayLikeProductList.recommendGoodsList.length) {
            commonFun.handleImpressionAnalyze(this.youMayLikeProductList.recommendGoodsList, 'You may like in homepage')
            utils.ga4('event', 'view_item_list', {
                item_list_id: 2,
                item_list_name: 'youMayLike',
                items: this.youMayLikeProductList.recommendGoodsList.map(sku => ({
                    item_id: sku.skuOrgId,
                    item_name: sku.title,
                    currency: this.$store.state.currentCountry.currency,
                    item_brand: sku.brandName,
                    item_category: sku.thirdCategoryNameBuried,
                    price: sku.standardPrice,
                    item_category2: sku.shopId,
                })),
            })
        }
        const bannerList = this.carouselItems.filter(item => item.activityId)
        // if (bannerList.length) {
        //     utils.ga4('event', 'view_promotion', {
        //         items: bannerList.map(item => ({
        //             item_id: item.activityId,
        //             item_name: item.title,
        //             promotion_id: item.activityId,
        //             promotion_name: item.title,
        //             creative_name: item.bannerId,
        //             creative_slot: 'banner',
        //             item_category2: item.shopId,
        //         })),
        //     })
        // }
        let userId = null
        if (this.$store.state.userId) {
            userId = this.$store.state.userId
        }
        if (process.env.CUSTOM_ENV === 'production') {
            window.hj('identify', userId, {
                userId,
            })
        }
        if (this.token) {
            this.getPoints()
        }
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.youMayLikeScrollHandler, false)
    }

    getPoints(){
        api.get_points_TH({pageEntrance: 1}).then(res => {
            this.pointInfo = res.data
            if (this.pointInfo) {
                if (this.pointInfo.excludePromotionList && this.pointInfo.excludePromotionList.length) {
                    this.pointInfo.excludePromotionListJoin = this.pointInfo.excludePromotionList.join()
                }
                if (this.pointInfo.validBeginTime) {
                    this.pointInfo.validBeginTime = utils.dataFormat(this.pointInfo.validBeginTime, 'dd/MM/yyyy')
                }
                if (this.pointInfo.validEndTime) {
                    this.pointInfo.validEndTime = utils.dataFormat(this.pointInfo.validEndTime, 'dd/MM/yyyy')
                }
            }
        })
    }

    i18nReplace(s, o) {
        return utils.i18nReplace(s, o)
    }

    jumpToPointDetail() {
        this.$router.push(this.localePath({
            name: 'myPointTH',
        }))
    }

    jumpToShopCart() {
        utils.ga4('event', 'click_place_order_to_get_points', {
            sourcePage: 'Homepage points level'
        })
        api.shopping_cart_list().then(res => {
            if (res.data.shopList && res.data.shopList.length) {
                this.$router.push(this.localePath({ name: 'orderProcedurePage' }))
            } else {
                this.$store.commit('SET_MODAL_STATUS', true)
            }
        })
    }

    getBanner() {
        this.carouselItems = []
        api.cms_banners_pc_find().then(res => {
            this.carouselItems = ((res && res.data) || []).map(item => {
                item.genImgUrl = this.$dealImgUrl(item.imgUrl, '', 396)
                return item
            })
        })
    }

    openBannerPage(item) {
        if (item.canJumpShopGoods) {
          let len = Math.floor(Math.random() * (item.shopIdList || []).length)
            this.$router.push(this.localePath({
                name: 'shopDetailPage',
                params: {
                  id: item.shopIdList[len],
                },
            }))
            return
        }
        if (item.linkUrl.includes('promotionLandingPageId')) {
            this.$router.push(this.localePath({
                name: 'newPromotion',
                query: {
                    id: item.linkUrl.split('=')[1],
                },
            }))
            return
        }

        utils.ga4('event', 'index_banner_ad', {
            id: item.id || '0',
        })

        utils.ga4('event', 'view_promotion', {
            promotion_id: item.activityId + '' || '0',
            promotion_name: item.title,
            shopId: item.shopId,
            items: [],
            id: item.id || '0',
            bannerId: item.bannerId + '' || '0',
        })
        // 先判断能否跳转到店铺详情页
        if (item.isDump == 2 && item.shopId) {
            this.$router.push(this.localePath({
                name: 'shopDetailPage',
                params: {
                    id: item.shopId,
                },
            }))
            return
        }

        if (item.isDump == 1) {
            if (item.openNewPage == 1) {
                const token = window.localStorage.getItem('token') || Cookies.get('token') || ''
                const countryInfo = common.getCountryInfo()
                const priceItem = window.localStorage.getItem('priceItem') || '{}'
                let priceId = ''
                if (JSON.parse(priceItem).priceId) {
                    priceId = JSON.parse(priceItem).priceId
                }
                window.open(`${item.linkUrl}${item.linkUrl.includes('?') ? '&' : '?'}businessPlatform=EGG_MALL_WEB&countryCode=${countryInfo.countryCode}&countryId=${countryInfo.countryId}&priceId=${priceId}&token=${token}`)
            } else {
                return utils.openBannerPromotionPage(this, item.linkUrl)
            }
        }
    }

    async getShopList() {
        let shops: any = {}
        if (this.is3PScene) {
            shops = await api.thirdP_home_shop_list()
        } else {
            shops = await api.home_shop_list()
        }
        this.shopListData = ((shops && shops.data) || []).slice(0, this.is3PScene ? 1 : 3)
        this.shopListData.forEach(shop => {
            if (!shop.skuList) {
                shop.skuList = Array.from({length: this.is3PScene ? 5 : 3}, (v, k) => k)
            }
        })
    }

    searchByFirstCategory(category) {
        this.$router.push(this.localePath({
            name: 'firstCategoryPage',
            params: {
                firstEggCategoryId: category.goodsCategoryId,
            },
            query: {
                searchResultInfo: JSON.stringify({
                    category: category.categoryName,
                }),
            },
        }))
    }

    searchBysubCategoryId(category, subCategory) {
        this.$router.push(this.localePath({
            name: 'subCategoryPage',
            params: {
                secondEggCategoryId: subCategory.goodsCategoryId,
            },
            query: {
                searchResultInfo: JSON.stringify({
                    // category: category.categoryName + subCategory.categoryName,
                    category: subCategory.categoryName,
                }),
            },
        }))
    }

    searchByBrandId(category) {
        this.$router.push(this.localePath({
            name: 'brandPage',
            params: {
                brandId: category.brandId,
            },
            query: {
                searchResultInfo: JSON.stringify({
                    brand: category.brandName,
                }),
            },
        }))
    }

    searchByThirdCategoryId(category) {
        this.$router.push(this.localePath({
            name: 'fastMovingPage',
            params: {
                fourCategoryId: category.goodsCategoryId,
            },
            query: {
                searchResultInfo: JSON.stringify({
                    thirdCategoryName: category.categoryName,
                }),
            },
        }))
    }

    gotoShopDetail(shop) {
        this.$router.push(this.localePath({
            name: 'shopDetailPage',
            params: {
                id: shop.shopId,
            },
        }))
    }

    gotoProductDetail(sku, item) {
        if (!sku.skuOrgId) {
            return
        }
        utils.ga4('event', 'select_item', {
            shopId: sku.shopId,
            items: [{
                item_id: sku.skuOrgId,
                item_name: sku.goodsName || sku.title,
                currency: this.$store.state.currentCountry.currency,
                item_brand: sku.brandName,
                item_category: sku.thirdCategoryNameBuried,
                price: sku.standardPrice,
                item_category2: sku.shopId,
            }],
        })
        if (item && item.type) {
            utils.ga4('event', item.type === 2 ? 'click_product_in_highligh_promo_sku_home' : 'click_product_in_new_arrivals_homepage', {
                item_id: item.skuOrgId,
                item_name: item.goodsName,
                item_brand: item.brandName,
                price: item.standardPrice,
                currency: this.$store.state.currentCountry.currency,
            })
        }
        this.$router.push(this.localePath({
            name: 'productDetailPage',
            params: {
                id: sku.skuOrgId,
            },
        }))
    }

    // 计算走马灯的高度
    computeCarouselHeight(items, rowNum) {
        if (!Array.isArray(items)) {
            return
        }
        if (!items[0] || !items[0].groupItems) {
            return
        }
        return Math.ceil(items[0].groupItems.length / rowNum)
    }

    changeTopProductPage() {
        this.topProductsPageParam.page++
        api.get_recommend_goods_page(this.topProductsPageParam).then(res => {
            this.topProductList = (res && res.data && res.data.items) || []
            if (this.topProductList.length) {
                utils.ga4('event', 'view_item_list', {
                    item_list_id: 1,
                    item_list_name: 'topProduct',
                    items: this.topProductList.map(sku => ({
                        item_id: sku.skuOrgId,
                        item_name: sku.title,
                        currency: this.$store.state.currentCountry.currency,
                        item_brand: sku.brandName,
                        item_category: sku.thirdCategoryNameBuried,
                        price: sku.standardPrice,
                        item_category2: sku.shopId,
                    })),
                })
            }
        })
    }

    youMayLikeScrollHandler() {
        if (document.documentElement.scrollHeight - document.documentElement.scrollTop < document.documentElement.clientHeight + 284) {
            if (!this.youMayLikeNoMoreData) {
                window.removeEventListener('scroll', this.youMayLikeScrollHandler, false)
                api.get_you_may_like(this.youMayLikePageParam).then(res => {
                    let items = (res && res.data && res.data.recommendGoodsList) || []
                    if (items.length < 10) {
                        this.youMayLikeNoMoreData = true
                    } else {
                        this.youMayLikeNoMoreData = false
                    }
                    commonFun.handleImpressionAnalyze(items, 'You may like in homepage')
                    this.youMayLikePageParam.showedSkuOrgIdList = this.youMayLikePageParam.showedSkuOrgIdList.filter(item => item)
                    this.youMayLikeProductList.recommendGoodsList = this.youMayLikeProductList.recommendGoodsList.concat(items)
                    if (items.length) {
                        window.addEventListener('scroll', this.youMayLikeScrollHandler, false)
                        utils.ga4('event', 'view_item_list', {
                            item_list_id: 2,
                            item_list_name: 'youMayLike',
                            items: items.map(sku => ({
                                item_id: sku.skuOrgId,
                                item_name: sku.title,
                                currency: this.$store.state.currentCountry.currency,
                                item_brand: sku.brandName,
                                item_category: sku.thirdCategoryNameBuried,
                                price: sku.standardPrice,
                                item_category2: sku.shopId,
                            })),
                        })
                        this.youMayLikePageParam.ruleOffset = res.data.ruleOffset
                        res.data.recommendGoodsList.map(item => {
                            this.youMayLikePageParam.showedSkuOrgIdList.push(item.skuOrgId)
                        })
                    }
                })
            }
        }
    }

    correctSSRPrice(type) {
        let params = type === 'topProduct' ? this.topProductsPageParam : this.youMayLikePageParam
        let list = type === 'topProduct' ? this.topProductList : this.youMayLikeProductList.recommendGoodsList
        api.get_recommend_goods_page({
            ...params,
            page: 0,
        }).then(res => {
            let data = (res && res.data && res.data.items) || []
            list.forEach(product => {
                data.forEach(item => {
                    if (product.skuOrgId === item.skuOrgId) {
                        product.standardPrice = item.standardPrice
                    }
                })
            })
            if (data.length) {
                utils.ga4('event', 'view_item_list', {
                    item_list_id: type === 'topProduct' ? 1 : 2,
                    item_list_name: type,
                    items: data.map(sku => ({
                        item_id: sku.skuOrgId,
                        item_name: sku.title,
                        currency: this.$store.state.currentCountry.currency,
                        item_brand: sku.brandName,
                        item_category: sku.thirdCategoryNameBuried,
                        price: sku.standardPrice,
                        item_category2: sku.shopId,
                    })),
                })
            }
        })
    }

    searchInputFastMoving() {
        let params = {
            categoryName: this.searchFastMoving,
        }
        api.fast_moving_list(params).then(res => {
            this.fastMoveingsGroup = (this as any).$commonSliceGroupFunc(res.data, 16)
        })
    }

    // 以下接口支持登录和未登录调用，但前后数据不一样，所以需要在token获取到后再次调用
    @Watch('token')
    onToken(newVal) {
        if (newVal) {
            this.getShopList()
            if (!common.getPriceId() || !common.getToken()) {
                // common.setToken(this.token)
                // common.setPriceId(this.priceItem.priceId)
                this.correctSSRPrice('topProduct')
                this.correctSSRPrice('youMayLike')
            }
            api.get_price_list().then(res => {
                this.priceIdList = (res && res.data) || []
                debugger
                if (this.priceIdList.length > 0) {
                    if (window.localStorage.getItem('priceItem')) {
                        let priceItem = window.localStorage.getItem('priceItem') || '{}'
                        this.selectedPriceItem = JSON.parse(priceItem)
                    } else {
                        this.selectedPriceItem = this.priceIdList[0]
                        common.setPriceId(this.selectedPriceItem.priceId)
                        this.$store.commit('SET_PRICE_ITEM', JSON.stringify(this.selectedPriceItem))
                    }

                    setTimeout(() => {
                        window.location.reload()
                    }, 50)
                }
            })
        }
    }

    openPromotionPage(item) {
        let params: any = {
            url: item.H5Url,
            customerId: '0',
            page_title: 'Homepage Promotion',
        }
        if (this.token) {
            // 登录就有用户的userId
            params.customerId = localStorage.getItem('userId') || ''
        }
        if (window.localStorage.getItem('customerType')) {
          params.customerType = window.localStorage.getItem('customerType')
        }

        utils.fbq('trackCustom', 'HomepageTapPromotion', params)
        utils.ga4('event', 'HomepageTapPromotion', params)
        let id = item.H5Url.split('?').filter(item => item.includes('promotionLandingPageId'))[0].split('=')[1]

        this.batchSave(Object.assign({promotionLandingPageID: id}, params), 3100)

        if (item.landingPageUsageType === 'BOSCH') {
          window.location.href = `${item.H5Url}&token=${this.token}`
        } else {
          this.$router.push(this.localePath({
            name: 'newPromotion',
            query: {
              id,
            },
          }))
        }
    }

    searchOEParts(item) {
        this.$store.commit('SET_OE_PARTS_BRAND', item)
        this.$store.commit('SET_MODAL_STATUS', true)
    }

    handleJumpToShopList() {
        this.$router.push(this.localePath({
            name: 'shopListPage',
        }))
    }

    handleJumpToBrandList() {
        this.$router.push(this.localePath({
            name: 'brandList',
        }))
    }

    goRecomendPage(type) {
        utils.ga4('event', type === 2 ? 'click_more_in_highligh_promo_sku_home' : 'click_more_in_new_arrivals_homepage')
        this.$router.push(this.localePath({
            name: type === 2 ? 'recommendBoughtProduct' : 'recommendNewProduct',
        }))
    }

    batchSave(customizeValue, elementId) {
      let params = {
        datas: [{
          appId: 'EGG_MALL_WEB',
          appVersion: 'EGG_MALL_WEB',
          carrier: 'EGG_MALL_WEB',
          customizeValue,
          deviceId: 'EGG_MALL_WEB',
          dynamicResourceType: 1,
          elementId,
          eventTime: 1,
          eventType: 1,
          ip: 'EGG_MALL_WEB',
          isFirstTime: 1,
          latitude: 'EGG_MALL_WEB',
          libVersion: 'EGG_MALL_WEB',
          longitude: 'EGG_MALL_WEB',
          manufacturer: 'EGG_MALL_WEB',
          model: 'EGG_MALL_WEB',
          networkType: 'EGG_MALL_WEB',
          operatingSystem: 'WEB',
          operatingSystemVersion: 'EGG_MALL_WEB',
          referPage: 'EGG_MALL_WEB',
          screenHeight: 'EGG_MALL_WEB',
          screenName: 'EGG_MALL_WEB',
          screenOrientation: 1,
          screenWidth: 'EGG_MALL_WEB',
          sessionId: 'EGG_MALL_WEB',
          timeZone: 'EGG_MALL_WEB',
          userId: this.$store.state.userId || 0,
          businessPlatform: 11,
        }],
      }
      api.event_tracking(JSON.stringify(params))
    }

}
